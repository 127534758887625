<template>
    <div class="grid grid-cols-12 gap-4 mt-30">
        <!-- Title -->
        <div class="col-span-12 md:col-span-6 flex items-center ml-48 mb-22">
            <h1 class="font-4xl opacity-85 text-gray-800 font-titillium-bold">{{ $t('memberspageTitle') }}</h1>
        </div>
        <!-- Add member -->
        <div class="col-span-12 md:col-span-6 flex justify-start md:justify-end items-center mb-22 ml-48 md:ml-0 md:mr-48 relative">
            <Button
                class="button bg-purple-500 text-white hover:bg-purple-600"
                role="button"
                :text="$t('membersAddMemberBtnText')"
                @btnClick="showAddModal = true; eraseErrors()"
            />
            <!-- Confirmation box for success messages -->
            <div
                class="fixed top-20 right-30 z-10000"
                ref="successBoxContainer"
            >
            </div>
        </div>
        <!-- Members table -->
        <div class="col-span-12 mx-30 py-30 bg-white rounded-lg shadow">
            <v-table
                id="members_table"
                class="table"
                :data="allMembers"
                :currentPage.sync="currentPage"
                :pageSize="pageSize"
                @totalPagesChanged="totalPages = $event"
                :hideSortIcons="true"
            >
                <!-- Members table columns -->
                <thead
                    class="thead"
                    slot="head"
                >
                    <th class="w-1/5 th">
                        <v-th
                            class="flex th items-center justify-between flex-row-reverse"
                            sortKey="name"
                            defaultSort="asc"
                        >
                            {{ $t('membersTabelColumn_1') }}
                        </v-th>
                    </th>
                    <th class="w-1/3 th">{{ $t('membersTabelColumn_2') }}</th>
                    <th class="w-1/5 th">{{ $t('membersTabelColumn_3') }}</th>
                    <th class="w-1/12 th">{{ $t('membersTabelColumn_4') }}</th>
                    <th class="w-1/6 th">{{ $t('membersTabelColumn_5') }}</th>
                    <th class="w-1/6 th">{{ $t('membersTabelColumn_6') }}</th>
                    <th class="w-1/6 th"></th>
                </thead>
                <tbody
                    id="table_body"
                    class="tbody"
                    slot="body"
                    slot-scope="{displayData}"
                >
                    <tr
                        class="border-b"
                        v-for="row in displayData"
                        :key="row.id"
                    >
                        <td class="p text-gray-800">
                            <div class="flex items-center">
                                <span class="th xl:hidden mr-7">{{ $t('membersTabelColumn_1') }}:</span>
                                {{ row.name }}
                            </div>
                        </td>
                        <td class="p text-gray-800">
                            <div class="flex items-center">
                                <span class="th xl:hidden mr-7">{{ $t('membersTabelColumn_2') }}:</span>
                                {{ row.email }}
                            </div>
                        </td>
                        <td class="p text-gray-800">
                            <div class="flex items-center">
                                <span class="th xl:hidden mr-7">{{ $t('membersTabelColumn_3') }}:</span>
                                {{ row.phone }}
                            </div>
                        </td>
                        <td class="text-base font-bold font-titillium-normal text-purple-600">
                            <div class="flex items-center">
                                <span class="th xl:hidden mr-7">{{ $t('membersTabelColumn_4') }}:</span>
                                {{ row.cases }}
                            </div>
                        </td>
                        <td>
                            <div class="flex items-center">
                                <span class="th xl:hidden mr-7">{{ $t('membersTabelColumn_5') }}:</span>
                                <div
                                    class="text-center rounded-full w-85 py-4 small"
                                    :class="[row.role === 'ngo-manager' ? 'bg-purple-500 text-white' : 'bg-yellow-500 text-purple-600']"
                                >
                                    {{ row.role === 'ngo-manager' ? 'Manager' : 'Staff' }}
                                </div>
                            </div>
                        </td>
                        <td
                            class="text-base font-titillium-normal"
                            :class="[(row.id !== null) ? 'text-purple-600' : 'text-red-600']"
                        >
                            <div class="flex items-center small">
                                <span class="th xl:hidden mr-7">{{ $t('membersTabelColumn_6') }}:</span>
                                {{ (row.id !== null) ? $t('membersRegistered') : $t('membersPending') }}
                            </div>
                        </td>
                        <td class="flex xl:justify-center xl:items-center">
                            <div v-on="row.id !== null ? { click: () => openEditModal({row}) } : {}">
                                <img
                                    class="mr-10 cursor-pointer"
                                    :src="require('@/assets/images/icons/edit.svg')"
                                    alt=""
                                >
                            </div>
                            <div v-on="row.id !== null ? { click: () => openDeleteModal({row}) } : {}">
                                <img
                                    class="cursor-pointer"
                                    :src="require('@/assets/images/icons/close.svg')"
                                    alt=""
                                >
                            </div>
                        </td>

                    </tr>
                </tbody>
            </v-table>
            <div
                v-if="allMembers.length"
                class="flex items-center justify-between mt-30"
            >
                <div class="pl-10 p text-gray-800">{{page}}-{{this.pageSizeIncrement}} {{ $t('fromText') }} {{allMembers.length}} {{allMembers.length > 1 ? $t('fromMemberstext') : $t('fromMemberstextSingle') }}</div>
                <div>
                    <smart-pagination
                        :currentPage.sync="currentPage"
                        :totalPages="totalPages"
                    />
                </div>
            </div>
            <div
                v-else
                class="bg-white rounded-lg shadow mb-30 p-20"
            >
                <p class="text-2xl opacity-85 tracking-tight text-gray-800 font-bold font-titillium-bold mb-20 md:mb-0">{{$t('noRecordsText')}}</p>
            </div>
        </div>
        <!-- Add member modal -->
        <Modal
            width='w-768'
            v-if="showAddModal"
            @close="showAddModal = false"
            @closeOnEscPressed="showAddModal = false"
        >
            <div slot="title">
                <h2 class="font-4xl font-titillium-bold opacity-85">{{$t('membersModalAddMemberTitle')}}</h2>
            </div>
            <p
                class="my-30 p text-gray-800"
                slot="subtitle"
            >
                {{$t('membersModalAddMemberSubtitle')}}
            </p>
            <div slot="body">
                <form
                    ref="addMemberForm"
                    @submit.prevent="onAddMemberSubmit"
                >
                    <div class="grid md:grid-cols-2 gap-30 mb-15">
                        <div>
                            <label class="flex">
                                {{ $t('membersFormRolFieldLabel') }}</label>
                            <Multiselect
                                class="rounded border"
                                :class="[errors.role ? 'border-red-500' : 'border-gray-200']"
                                v-model="role"
                                :options="roles"
                                label="name"
                                track-by="name"
                                :placeholder="$t('membersFormRolFieldLabel')"
                                :deselectLabel="$t('selectRemoveOptionText')"
                                :selectLabel="$t('selectOptionText')"
                                :selectedLabel="$t('selectOptionSelectedText')"
                                :selectGroupLabel="$t('selectGroupOptionsText')"
                                :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                            >
                                {{role}}
                                <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                                <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                                <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                            </Multiselect>
                        </div>
                        <Input
                            :error="errors.name"
                            :class="[errors.name ? 'mb-4' : 'mb-22']"
                            :label="$t('membersFormNameFieldLabel')"
                            :placeholder="$t('membersFormNameFieldLabel')"
                            type="text"
                            name="name"
                        />
                    </div>
                    <div class="grid md:grid-cols-2 gap-30 mb-15">
                        <Input
                            :error="errors.email"
                            :class="[errors.email ? 'mb-4' : 'mb-22']"
                            :label="$t('membersFormEmailFieldLabel')"
                            :placeholder="$t('membersFormEmailFieldLabel')"
                            type="email"
                            name="email"
                        />
                        <Input
                            :error="errors.phone"
                            :class="[errors.phone ? 'mb-4' : 'mb-22']"
                            :label="$t('membersFormPhoneFieldLabel')"
                            :placeholder="$t('membersFormPhoneFieldLabel')"
                            type="phone"
                            name="phone"
                        />
                    </div>
                    <div class="flex justify-end">
                        <Button
                            class="button bg-purple-500 text-white hover:bg-purple-600"
                            role="submit"
                            :text="$t('membersAddFormBtnText')"
                            :disabled="memberAddLoading"
                            :spinning="memberAddLoading"
                        />
                    </div>
                </form>
            </div>
        </Modal>
        <!-- Edit member modal -->
        <Modal
            width='w-768'
            v-if="showEditModal"
            @close="showEditModal = false"
            @closeOnEscPressed="showEditModal = false"
        >
            <div slot="title">
                <h2 class="font-4xl font-titillium-bold opacity-85">{{$t('membersModalEditMemberTitle')}}</h2>
            </div>
            <p
                class="my-30 p text-gray-800"
                slot="subtitle"
            >
                {{$t('membersModalEditMemberSubtitle')}}
            </p>
            <div slot="body">
                <form
                    ref="editMemberForm"
                    @submit.prevent="onEditMemberSubmit"
                >
                    <div class="grid md:grid-cols-2 gap-30 mb-15">
                        <div>
                            <label class="flex">{{ $t('membersFormRolFieldLabel') }}</label>
                            <Multiselect
                                class="rounded border"
                                :class="[errors.role ? 'border-red-500' : 'border-gray-200']"
                                v-model="memberDetail.role"
                                :options="roles"
                                label="name"
                                track-by="name"
                                :placeholder="$t('membersFormRolFieldLabel')"
                                :deselectLabel="$t('selectRemoveOptionText')"
                                :selectLabel="$t('selectOptionText')"
                                :selectedLabel="$t('selectOptionSelectedText')"
                                :selectGroupLabel="$t('selectGroupOptionsText')"
                                :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                            >
                                <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                                <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                                <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                            </Multiselect>
                        </div>
                        <Input
                            :error="errors.name"
                            :class="[errors.name ? 'mb-4' : 'mb-22']"
                            :label="$t('membersFormNameFieldLabel')"
                            :placeholder="$t('membersFormNameFieldLabel')"
                            type="text"
                            name="name"
                            :value="memberDetail.name"
                        />
                    </div>
                    <div class="grid md:grid-cols-2 gap-30 mb-15">
                        <Input
                            :error="errors.email"
                            :class="[errors.email ? 'mb-4' : 'mb-22']"
                            :label="$t('membersFormEmailFieldLabel')"
                            :placeholder="$t('membersFormEmailFieldLabel')"
                            type="email"
                            name="email"
                            :value="memberDetail.email"
                        />
                        <Input
                            :error="errors.phone"
                            :class="[errors.phone ? 'mb-4' : 'mb-22']"
                            :label="$t('membersFormPhoneFieldLabel')"
                            :placeholder="$t('membersFormPhoneFieldLabel')"
                            type="phone"
                            name="phone"
                            :value="memberDetail.phone.slice(3)"
                        />
                    </div>
                    <div class="flex justify-end">
                        <Button
                            class="button bg-purple-500 text-white hover:bg-purple-600"
                            role="submit"
                            :text="$t('membersEditFormBtnText')"
                            :disabled="memberEditLoading"
                            :spinning="memberEditLoading"
                        />
                    </div>
                </form>
            </div>
        </Modal>
        <!-- Delete member modal -->
        <Modal
            width='w-420'
            v-if="showDeleteModal"
            @close="showDeleteModal = false"
            @closeOnEscPressed="showDeleteModal = false"
        >
            <div slot="title">
                <h2 class="font-4xl text-gray-800 font-titillium-bold opacity-85">{{ $t('deleteMemberTitle') }}</h2>
            </div>
            <p
                class="my-30 p text-gray-800"
                slot="subtitle"
            >
                {{ $t('deleteMemberSubtitle') }} {{member.name}}?
            </p>
            <div slot="body">
                <div class="grid grid-cols-2 gap-30">
                    <Button
                        @btnClick="showDeleteModal = false"
                        class="button bg-gray-500 hover:bg-gray-600 text-white w-full px-30 mb-30"
                        role="button"
                        :text="$t('deleteServiceNoButtonText')"
                    />
                    <Button
                        @btnClick="memberDelete(member)"
                        class="button bg-red-500 hover:bg-red-600 text-white w-full px-30 mb-30"
                        role="button"
                        :text="$t('deleteServiceYesButtonText')"
                        :disabled="memberDeleteLoading"
                        :spinning="memberDeleteLoading"
                    />
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Vue from 'vue'
import Button from '@/components/buttons/Button'
import Modal from '@/components/modal/Modal'
import Input from '@/components/form/Input'
import SuccessBox from '@/components/success-message/SuccessBox'
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Members',
    components: {
        Button,
        Modal,
        Input,
        Multiselect
    },
    props: ['showModalProp'],
    data () {
        return {
            role: '',
            roles: [
                { value: 'ngo-manager', name: 'Manager' },
                { value: 'ngo-staff', name: 'Staff' }],
            currentPage: 1,
            totalPages: 0,
            showAddModal: false,
            showEditModal: false,
            memberDetail: null,
            showDeleteModal: false,
            member: '',
            pageSize: 20,
            pageSizeIncrement: null,
            currentPageSize: null,
            page: null,

            memberAddLoading: false,
            memberEditLoading: false,
            memberDeleteLoading: false
        }
    },
    computed: {
        ...mapGetters(['allMembers', 'token', 'errors', 'error', 'addMemberStatus', 'editMemberStatus', 'requestStatus', 'prefix'])
    },
    methods: {
        ...mapActions(['getMembers', 'addMember', 'editMember', 'eraseErrors', 'deleteMember']),
        /** Table filter */
        name (row) {
            return row.name
        },
        /** Add member. */
        onAddMemberSubmit () {
            this.memberAddLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const newMember = {
                role: this.role.value,
                name: this.$refs.addMemberForm.name.value,
                email: this.$refs.addMemberForm.email.value,
                phone: this.prefix + this.$refs.addMemberForm.phone.value
            }

            this.addMember({ token: this.token, member: newMember })
                .then(response => {
                    if (this.addMemberStatus === true) {
                        this.memberAddLoading = false
                        this.showAddModal = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('addMemberSuccessMessage'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.memberAddLoading = false
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },
        openEditModal (item) {
            this.memberDetail = JSON.parse(JSON.stringify(item.row))
            this.memberDetail.role = {
                value: item.row.role,
                name: item.row.role === 'ngo-manager' ? 'Manager' : 'Staff'
            }
            this.showEditModal = true
            this.eraseErrors()
        },
        /** Edit member. */
        onEditMemberSubmit () {
            this.memberEditLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const editMember = {
                id: this.memberDetail.id,
                name: this.$refs.editMemberForm.name.value,
                phone: this.prefix + this.$refs.editMemberForm.phone.value,
                email: this.$refs.editMemberForm.email.value,
                role: this.memberDetail.role.name === 'Manager' ? 'ngo-manager' : 'ngo-staff',
                cases: this.memberDetail.cases
            }

            this.editMember({ token: this.token, member: editMember })
                .then(response => {
                    if (this.editMemberStatus === true) {
                        this.memberEditLoading = false
                        this.showEditModal = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('editMemberSuccessMessage'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.memberEditLoading = false
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },
        /** Open confirmation modal for deleting file. */
        openDeleteModal (item) {
            this.member = item
            this.showDeleteModal = true
        },
        /** Delete member. */
        memberDelete (item) {
            this.memberDeleteLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.deleteMember({
                token: this.token,
                id: item.id
            }).then(response => {
                if (this.requestStatus === true) {
                    this.memberDeleteLoading = false
                    this.allMembers.splice(this.allMembers.indexOf(item), 1)
                    this.showDeleteModal = false

                    /** Dynamically mount success box to page */
                    const instance = new SuccessBoxComponent({
                        propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('deleteMemberMessage'), success: true }
                    })
                    instance.$mount()
                    this.$refs.successBoxContainer.appendChild(instance.$el)
                } else {
                    this.memberDeleteLoading = false
                    if (this.error !== '') {
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    }
                }
            })
        },

        /** Display info table in table footer method */
        getTableRecordsInfo () {
            this.currentPageSize = document.getElementById('table_body').childElementCount
            this.page = (this.pageSize * (this.currentPage - 1)) + 1

            if (this.allMembers.length < (this.pageSize * this.currentPage)) {
                this.pageSizeIncrement = (this.pageSize * (this.currentPage - 1)) + this.currentPageSize
            } else {
                this.pageSizeIncrement = (this.pageSize * this.currentPage)
            }
        }
    },
    updated () {
        this.getTableRecordsInfo()
    },
    mounted () {
        /** Prop from dashboard to get the add modal on mount */
        if (this.showModalProp) {
            this.showAddModal = true
        }

        /** get members list */
        this.getMembers({ token: this.token }).then(() => {
            this.getTableRecordsInfo()
        })
    }
}
</script>
